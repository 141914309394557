import { useField } from 'formik';
import clsx from 'clsx';
import {useState} from 'react';
import CurrencyFormat from 'react-currency-format';
import { useSelector } from 'react-redux';


export const CurrencyField = ({label, required, floating, value, onChange, ...props}) => {
   const [field, meta] = useField(props);
   const [active, setActive] = useState(false);
   const currencyPrefix = useSelector(state => state?.walletData?.wallet?.currency) === "usd" ? "$":"₦";
   
    return(
        <div className='relative'>
            <label 
                htmlFor={props.name}
                className={clsx( 
                    "text-base text-cc-black-4",
                    (active && floating) ? "text-xs top-[4px]":"top-[12px]",
                    floating ? "absolute left-[16px] transition-all" : "font-semibold",
                )}
            >
                {label}
                {
                    required &&
                    <span className='text-cc-red-4'>*</span>
                }
            </label>
            <CurrencyFormat 
                {...field} 
                {...props} 
                onFocus={()=>setActive(true)}
                thousandSeparator={true} 
                prefix={currencyPrefix}
                value={value}
                decimalScale={2}
                placeholder={"Enter Amount"}
                fixedDecimalScale={true}
                allowNegative={false}
                onValueChange={(values) => {
                    onChange(values)
                }}
                className={clsx(
                    `w-full border-[1px] text-cc-grey-3 mt-1 border-solid py-[7px] pr-1 pl-4 rounded-[5px] focus:outline-none focus:border-cc-primary mb-6`,
                    meta.touched && meta.error ? 'border-cc-red-4':'border-cc-grey-12'
                )}
            />
            {
                (meta.touched && meta.error) &&
                <p className='text-cc-red-1 text-xs absolute bottom-[7px]'>{meta.error}</p>
            }
        </div>
    )
}