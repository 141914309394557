import { TextField } from "components/Form/TextField";
import { FormikProvider, useFormik } from 'formik';
import { SelectField } from "components/Form/SelectField";
import Button from "components/shared/Button";
import { DateField } from "components/Form/DateField";
import { Tooltip } from "react-tooltip";
import { CurrencyField } from "components/Form/CurrencyField";
import { useEffect, useState } from "react";
import { createMandateSchema } from "utils/validationSchemas";
import { dateFormatter, handleRequestErrors } from "utils/helpers";
import { RecovaService } from "services/recova.service";
import { useUser } from "hooks/redux.hook";
import ShareMandateModal from "./components/ShareMandateModal";
import { useSelector } from "react-redux";
import startDateTip from "images/svg/recova/startdate_tip.svg";
import HistoryTable from "./components/HistoryTable";
import linkBlue from "images/svg/recova/linkBlue.svg"
import dayjs from "dayjs";
import { useFeedbackModal } from "hooks/feedback.hook";
import { RiErrorWarningFill } from "react-icons/ri";


export default function Create ({creationBVN}) {
    const [submitLoading, setSubmitLoading] = useState(false)
    // const { recovaSetupData } = useRecovaSetup();
    const {b2b_data} = useUser()
    const [showModal, setShowModal] = useState(false);
    const [mandateId, setMandateId] = useState("")
    const appId = useSelector(state => state?.app?.active?._id)
    const [productNames, setProductNames] = useState([])
    const [productsLoading, setProductsLoading] = useState(false)
    const [reloadHistory, setReloadHistory] = useState(true)
    const { showFeedback } = useFeedbackModal()

    useEffect(()=>{
        setProductsLoading(true)
        RecovaService.getProducts()
            .then(response=> response.data)
            .then(res => {
                setProductsLoading(false)
                if(res.status===true) {
                    setProductNames(res?.data)
                }
            })
            .catch((err) => {
                setProductsLoading(false)
                // handleRequestErrors(err, true)
            });
    }, [])

    const form = useFormik({
        initialValues: {
            product_id: "",
            bvn: creationBVN || '',
            amount_due: '',
            frequency: '',
            duration: '',
            start_date: '',
            end_date: '',
            collection_mode: '',
            debitType: '',
            mandateType: "",
            debitDate: "",
            firstRepaymentPercentage: '',
            acc_type: "",
            rc: "",
            businessName: "",
            businessEmail: "",
            businessPhone: "",
        },
        validationSchema: createMandateSchema, 
        onSubmit: (values) => {
            const isCorporate = (values?.acc_type==="business")
            let payload = JSON.stringify({
                productId: values.product_id,
                bvn: values.bvn,
                frequency: values.frequency.toLowerCase(),
                startDate: values.start_date,
                endDate: values.end_date,
                totalAmountDue: values.amount_due,
                collectionMode: values.collection_mode,
                debitType: values.debitType,
                mandateType: values.mandateType,
                corporateAccount: isCorporate,
                ...(values?.debitDate ? {debitDate: values.debitDate}:{}),
                ...(values?.firstRepaymentPercentage ? {firstRepaymentPercentage: Number(values.firstRepaymentPercentage)}:{}),
                ...(isCorporate ? {rcNumber: values.rc}:{}),
                ...(isCorporate ? {businessName: values.businessName}:{}),
                ...(isCorporate ? {businessEmail: values.businessEmail}:{}),
                ...(isCorporate ? {businessPhone: values.businessPhone}:{}),
            })
            setSubmitLoading(true)
            handleSubmit(payload)
        }
    })

    const handleSubmit = (payload) => {
        RecovaService.createMandate(payload)
            .then(response=> response.data)
            .then(res => {
                setSubmitLoading(false)
                if(res.status) {
                    setShowModal(true)
                    setMandateId(res?.data?._id||"")
                    setReloadHistory(!reloadHistory)
                    form.resetForm()
                }
            })
            .catch((err) => {
                setSubmitLoading(false)
                handleRequestErrors(err)
            });
    }

    return(
        <div>
            <div className="flex items-center gap-1.5 mt-4">
                <p className="text-base text-cc-grey-18 font-semibold">Fill in a details correctly to create a link for e-Mandate</p>
                <RiErrorWarningFill className="w-6" data-tooltip-id={"top-warning"} />
                <Tooltip 
                    id={"top-warning"} 
                    place="right"
                    render={()=>(
                        <div className="text-xs text-white w-[200px]">
                            <p className="font-semibold mb-1">e-Mandate Link</p>
                            <p>Please note that mandates that doesn't have any consent will be removed after 14days</p>
                        </div>
                    )} 
                />
            </div>
            <div className="max-w-[800px] mt-[20px]">
                <FormikProvider value={form}>  
                    <form onSubmit={form.handleSubmit} className="grid grid-cols-2 gap-x-[30px]">
                        <SelectField
                            name='product_id'
                            options={
                                productNames.map(item=>({
                                    label: item.name,
                                    value: item._id
                                }))
                            }
                            value={form?.values?.product_id}
                            label="Product Name"
                            onChange={(selected)=>{
                                form.setFieldValue( "product_id", selected.value )
                            }}
                            isLoading={productsLoading}
                            required
                        />
                        <CurrencyField
                            onChange={(val)=>{
                                form.setFieldValue("amount_due", val.value )
                            }}
                            value={form?.values?.amount_due}
                            name='amount_due*'
                            label="Total Amount Due for Collection"
                            required
                        />
                        <SelectField
                            name='acc_type'
                            options={
                                [
                                    {
                                        label: "Personal Account",
                                        value: "personal"
                                    },
                                    {
                                        label: "Business Account",
                                        value: "business"
                                    }
                                ].map(item=>({
                                    label: item.label,
                                    value: item.value
                                }))
                            }
                            value={form?.values?.acc_type}
                            label="Account Type"
                            onChange={(selected)=>{
                                form.setFieldValue( "acc_type", selected.value )
                            }}
                            required
                        />
                        <TextField
                            name='bvn'
                            type='text'
                            label="BVN"
                            required
                            placeholder="Enter 11 digit BVN"
                        />
                        {
                            form?.values?.acc_type==="business" &&
                            <>
                                <TextField
                                    name='businessName'
                                    type='text'
                                    label="Business Name"
                                    required
                                    placeholder="Enter Business Name"
                                />
                                <TextField
                                    name='rc'
                                    type='text'
                                    label="RC/BN"
                                    required
                                    placeholder="Enter RC/BN"
                                />
                                <TextField
                                    name='businessEmail'
                                    type='email'
                                    label="Business Email"
                                    required
                                    placeholder="Enter Business Email"
                                />
                                <TextField
                                    name='businessPhone'
                                    type='text'
                                    label="Business Phone Number"
                                    required
                                    placeholder="Enter Business Phone Number"
                                />
                            </>
                        }
                        <SelectField
                            name='mandateType'
                            options={[
                                // { value: 'fixed', label: 'Fixed' },
                                { value: 'variable', label: 'Variable' },
                            ]}
                            label="Mandate Type"
                            value={form?.values?.mandateType}
                            onChange={(selected)=>{
                                form.setFieldValue( "mandateType", selected.value )
                            }}
                            required
                        />
                        <SelectField
                            name='frequency'
                            options={['Daily', 'Weekly', 'Monthly'].map(item => ({
                                value: item,
                                label: item
                            }))}
                            label="Frequency"
                            onChange={(selected)=>{
                                form.setFieldValue( "frequency", selected.value )
                            }}
                            value={form?.values?.frequency}
                            required
                        />
                        <SelectField
                            name='collection_mode'
                            options={[
                                { value: 'manual', label: 'Manually' },
                                { value: 'auto', label: 'Automatically' },
                            ]}
                            value={form?.values?.collection_mode}
                            label="Collection Mode"
                            onChange={(selected)=>{
                                form.setFieldValue( "collection_mode", selected.value )
                            }}
                            required
                        />
                        <div className="flex gap-x-[20px]">
                            <div className="w-1/2 box-border relative">
                                <DateField
                                    name='start_date'
                                    label="Start Date"
                                    onChange={(value)=>{
                                        form.setFieldValue( "start_date", dateFormatter(value, 'yyyy-mm-dd') )
                                    }}
                                    required
                                    value={form?.values?.start_date}
                                    placeholder="Select date"
                                    errorMessageClass="!top-[72px] leading-[12px]"
                                    minDate={dayjs().add(7, 'day').toDate()}
                                />
                                <img 
                                    src={startDateTip} 
                                    alt="tip"
                                    data-tooltip-id={"start-date"}
                                    className="w-3.5 absolute top-1.5 left-[86px] cursor-default" 
                                />
                                <Tooltip 
                                    id={"start-date"} 
                                    place="top"
                                    render={()=>(
                                        <div className="text-xs text-white w-[200px]">
                                            <p className="font-semibold mb-1">Collection Start Date</p>
                                            <p>Please ensure that the start date for collecting this funds is at least 7 days from today as enforced by the Central Bank’s Direct Debit Scheme.</p>
                                        </div>
                                    )} 
                                />
                            </div>
                            <div className="w-1/2 box-border">
                                <DateField
                                    name='end_date'
                                    label="End Date"
                                    errorMessageClass="!top-[74px] leading-[12px]"
                                    onChange={(value)=>{
                                        form.setFieldValue( "end_date", dateFormatter(value, 'yyyy-mm-dd') )
                                    }}
                                    value={form?.values?.end_date}
                                    placeholder="Select date"
                                    required
                                    minDate={dayjs(form?.values?.start_date).add(1, 'day').toDate()}
                                />
                            </div>
                        </div>
                        <SelectField
                            name='debitType'
                            options={[
                                { value: 'oneTime', label: 'One time' },
                                { value: 'recurring', label: 'Recurring' },
                            ]}
                            value={form?.values?.debitType}
                            label="Debit Type"
                            onChange={(selected)=>{
                                form.setFieldValue( "debitType", selected.value )
                            }}
                            required
                        />
                        <TextField
                            name='firstRepaymentPercentage'
                            type='number'
                            label="First Repayment Percentage (optional)"
                            placeholder="Enter values between 1-100"
                            min={0}
                            max={100}
                        />
                        <div className="mt-[16px]">
                            <Button
                                isValid={true}
                                isLoading={submitLoading}
                                type="submit"
                                text="Create Link"
                                className="mb-[30px] mt-2.5"
                            />
                        </div>
                    </form>
                </FormikProvider>
            </div>
            <HistoryTable 
                title={"History of Created Links"}
                icon={linkBlue}
                reloadHistory={reloadHistory}
            />
            <ShareMandateModal
                show={showModal}
                link={`${window.location.origin}/customer/direct-debit/${mandateId}/${b2b_data.user.businessId}?appId=${appId}&account=primary`}
                handleClose={()=>{
                    setShowModal(false)
                    showFeedback("RecovaPRO Create Mandate")
                }}
            />
        </div>
    )
}