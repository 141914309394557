import Button from "components/shared/Button";


export default function MandateConsent ({submitApplication}) {
    return (
        <div className="max-w-[800px] block mx-auto mt-10">\
            <div className=" opacity-20">
                <p className="mt-10 mb-2.5">Open or share link to setup mandate consent to allow us collect repayment in the event of a default</p>
                {
                    ["Obligor (Guarantor 1)", "Obligor (Guarantor 2)"].map(item => (
                        <div className="">
                            <p>{item}</p>
                            <div className="flex gap-[10px] mt-2.5 mb-6">
                                <div className="border-[1px] border-[#CCDCFF] rounded py-1 px-3">
                                    <p>https://www.creditchek.recovamandate.com</p>
                                </div>
                                <Button
                                    isValid={true}
                                    type="button"
                                    text="Continue"
                                    className="whitespace-nowrap px-3"
                                />
                                <Button
                                    isValid={true}
                                    type="button"
                                    text="Send Consent"
                                    className="whitespace-nowrap px-3"
                                />
                            </div>
                        </div>
                    ))
                }
            </div>
            <Button
                fullwidth={false}
                isValid={true}
                type="button"
                onClick={()=>submitApplication()}
                text="Submit"
                className="whitespace-nowrap px-3 mt-10 w-[100px] mb-[26px]"
            />
        </div>
    )
}