import { TextField } from "components/Form/TextField";
import { FormikProvider, useFormik } from "formik";
import Button from "components/shared/Button";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BankService } from "services/bank.service";
import { selectMandateBankSchema } from "utils/validationSchemas";
import { SelectField } from "components/Form/SelectField";
import { RecovaService } from "services/recova.service";
import { handleRequestErrors } from "utils/helpers";


export default function SelectBank () {
    const [searchParams] = useSearchParams();
    const [ngBanks, setNgBanks] = useState([]);
    const [microDepositBanks, setMicroDepositBanks] = useState([]);
    let history = useNavigate()

    const form = useFormik({
        initialValues: {
            accountNumber: "",
            paymentType: searchParams.get("account")==="primary" ? "Primary":"Backup",
            accountName: "",
            bank: null,
            logo: "",
            bankName: "",
            bankCode: "",
            email: "",
        },
        validationSchema: selectMandateBankSchema,
        onSubmit: (values) => {
            if(searchParams.get("account")==="primary"){
                sessionStorage.setItem("cc_dd_selectedPrimaryBank", JSON.stringify(values))
            } else {
                sessionStorage.setItem("cc_dd_selectedBackupBank", JSON.stringify(values))
            }
            history(`/customer/direct-debit/mandate?account=${searchParams.get("account")}`)
        }
    })

    useEffect(()=>{
        BankService.getAllBankLogo()
            .then(resp=>{
                setNgBanks(resp.data);
            }) 
        RecovaService.getMicrodepositBanklist()
            .then((response) => response.data)
            .then((res) => {
                if(res.status===true){
                    setMicroDepositBanks(res?.data ?? [])
                }
            })
            .catch((err) => {})
    }, [])

    useEffect(()=>{
        if(!!form.values.bank && form.values.accountNumber.length===10){
            let payload = JSON.stringify({
                bankCode: form.values.bank.nip_code,
                accountNumber: form.values.accountNumber,
            })
            RecovaService.directDebitNameEnquiry(payload)
                .then(response=> response.data)
                .then(res => {
                    form.setFieldValue("accountName", res.data);
                })
                .catch((err) => {
                    handleRequestErrors(err, true)
                });
        } else {
            form.setFieldValue("accountName", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.bank, form.values.accountNumber])

    return (
        <div className="relative">
            <div 
                className={clsx(
                    "bg-cc-primary py-10 px-4 min-h-[80vh] overflow-hidden bg-[url('./images/svg/sidebar/bg-image.svg')]",
                )}
            >
                <div>
                    <div className="pt-8 box-border">
                        <p className="font-medium text-white text-[17px] mb-10">Enter your Bank Account Details correctly to enable Direct debit</p>
                        <FormikProvider value={form}>
                            <form onSubmit={form.handleSubmit}>
                                <div className="-mt-3.5">
                                    <TextField
                                        label="Account Number"
                                        name="accountNumber"
                                        placeholder="Enter Account number"
                                        labelColor="text-white"
                                        // value={form.values.accountNumber}
                                    />
                                </div>
                                <div className="-mt-3.5">
                                    <SelectField
                                        label="Bank"
                                        name="bank"
                                        placeholder="Select bank"
                                        labelColor="text-white"
                                        options={microDepositBanks?.map(item => ({
                                            label: item.name,
                                            value: item
                                        }))}
                                        value={form.values.bankName}
                                        searchable={true}
                                        onChange={(val)=> {
                                            form.setFieldValue("bank", val?.value)
                                            form.setFieldValue("bankCode", val?.value?.bank_code)
                                            form.setFieldValue("bankName", val?.value?.name)
                                            form.setFieldValue("logo", ngBanks.find(o => o.code === val?.value?.bank_code)?.logo?.url)
                                        }}
                                    />
                                </div>
                                <div className="-mt-3.5">
                                    <TextField
                                        label="Account Name"
                                        name="accountName"
                                        labelColor="text-white"
                                        readOnly
                                    />
                                </div>
                                <div className="-mt-3.5">
                                    <TextField
                                        label="Payment Type"
                                        name="paymentType"
                                        labelColor="text-white"
                                        readOnly
                                        value={form.values.paymentType}
                                    />
                                </div>
                                <div className="-mt-3.5">
                                    <TextField
                                        label="Email Address"
                                        type="email"
                                        name="email"
                                        labelColor="text-white"
                                        placeholder="Enter Email Address"
                                    />
                                </div>
                                <Button
                                    text="Proceed"
                                    type="submit"
                                    isValid={true}
                                    className="bg-white font-semibold !text-cc-primary mt-3"
                                />
                            </form>
                        </FormikProvider>
                    </div>
                </div>
            </div>
        </div>
    )
}