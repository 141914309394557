import { createSlice } from '@reduxjs/toolkit'


export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    wallet: {
      balance: 0,
      account_name: "MERCHANT(CreditChek Business)",
      account_number: "997****585",
      alert: true,
      alert_limit: 1000,
      currency: "naira"
    },
  },
  reducers: {
    setWallet: (state, action) => {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          ...action.payload,
          currency: action?.payload?.currency ?? "naira"
        }
      }
    },
    setWalletAlert: (state, action) => {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          alert: action.payload
        }
      }
    },
    setAlertLimit: (state, action) => {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          alert_limit: action.payload
        }
      }
    },
    setCurrency: (state, action) => {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          currency: action?.payload ?? "naira"
        }
      }
    },
  },
})

export const { setWallet, setWalletAlert, setAlertLimit, setCurrency } = walletSlice.actions

export default walletSlice.reducer