import { useField } from 'formik';
import clsx from 'clsx';
import {useState} from 'react';
import pdfIcon from "images/svg/upload-pdf2.svg";
import fileRemove from "images/svg/file-remove.svg";
import file from "images/svg/identity/file.svg";
import dayjs from 'dayjs';
import { formatFileSize } from 'utils/helpers';
import { toast } from 'react-toastify';


export const UploadFile = ({
    label, required, floating, labelColor, onChange,
    fileType="both", // pdf | image | both
    customUploadView, // optional reactnode
    maxFileSize, // optional number (mb)
    ...props // name
}) => {
    const [field, meta] = useField(props);
    const [active, setActive] = useState(false);
    const [useCamera, setUseCamera] = useState(false)
    const inputAccept = fileType==="pdf" ? "application/pdf"
        : fileType==="image" ? "image/*"
        : "application/pdf image/*"
        
    return(
        <div className='relative'>
            <label 
                htmlFor={props.name}
                className={clsx( 
                    "text-base",
                    (active && floating) ? "text-xs top-[4px]":"top-[12px]",
                    floating ? "absolute left-[16px] transition-all" : "font-semibold mb-1",
                    labelColor,
                )}
            >
                {label}
                {
                    required &&
                    <span className='text-cc-red-4'>*</span>
                }
            </label>
            <input 
                name={props.name}
                type="file" 
                accept={inputAccept} 
                id={'input_file'+props.name}
                onChange={(e)=>{
                    if(!!maxFileSize){
                        if(e.target.files[0].size < 1024*1024*maxFileSize){
                            onChange(e.currentTarget.files[0])
                        } else {
                            toast.error(`Maximum file size allowed is ${maxFileSize}mb`)
                        }
                    } else {
                        onChange(e.currentTarget.files[0])
                    }
                }} 
                hidden 
                onFocus={()=>setActive(true)}
                {...(useCamera ? { capture: 'environment' } : {})}
                // capture="user"
                // // accept="image/*" 
                // accept="image/*;capture=camera"
            />
            {
                !!customUploadView ? 
                <div 
                    onClick={()=>{
                        document.getElementById('input_file'+props.name).click()
                    }}
                >
                    {customUploadView}
                </div>
                :
                <div>
                    <div className='w-[250px] grid grid-cols-2 border-[1px] border-cc-grey-21 text-sm font-medium cursor-pointer rounded-lg overflow-hidden'>
                        <div 
                            className='text-center bg-cc-grey-21 text-white py-2' 
                            onClick={()=>{
                                setUseCamera(false)
                                document.getElementById('input_file'+props.name).click()
                            }}
                        >
                            <p>Choose File</p>
                        </div>
                        <div 
                            className='text-center text-cc-grey-21 py-2'
                            onClick={()=>{
                                setUseCamera(true)
                                document.getElementById('input_file'+props.name).click()
                            }}
                        >
                            <p>Take a picture</p>
                        </div>
                    </div>
                    {
                        !!maxFileSize &&
                        <p className="text-xs text-red-600">{maxFileSize}MB maximum file size</p>
                    }
                    
                </div>
            }
            {
                !!field?.value && 
                <div className='max-w-[300px]'>
                    <div className="flex items-center gap-1.5 border-[1px] border-dashed border-cc-primary rounded p-2.5 mt-[15px]">
                        <img 
                            src={field?.value?.type==="application/pdf" ? pdfIcon:file} 
                            alt="pdf" 
                            className='w-9 shrink-0' 
                        />
                        <div className="overflow-hidden">
                            <h6 className="text-sm">{field?.value?.name}</h6>
                            <div className="capitalize text-xs flex justify-between gap-10">
                                <p>{dayjs(field?.value?.lastModifiedDate).format("DD/MM/YYYY")}</p>
                                <p>{formatFileSize(field?.value?.size)}</p>
                            </div>
                        </div>
                        <img 
                            src={fileRemove} 
                            alt="remove" 
                            className='w-[20px] ml-auto cursor-pointer shrink-0' 
                            onClick={()=>onChange(null)}
                        />
                    </div>
                </div>
            }
            <div className='h-6'/>
            {
                (meta.touched && meta.error) &&
                <p className='text-cc-red-1 text-xs absolute bottom-[7px]'>{meta.error}</p>
            }
        </div>
    )
}