import Back from "components/shared/back";
import { FormikProvider, useFormik } from "formik";
import { TextField } from "components/Form/TextField";
import { SelectField } from "components/Form/SelectField";
import Button from "components/shared/Button";
import { complianceDocument } from "utils/validationSchemas";
import { useEffect, useState } from "react";
import { UploadFile } from "components/Form/UploadFile";
import { useSelector } from "react-redux";
import upload from "images/svg/home/upload.svg";
import { IdentityService } from "services/identity.service";
import FormLoader from "./components/FormLoader";

function Document ({prevButton, nextButton}) {
    const [isForm, setIsForm] = useState(true)
    const isNigerianBusiness = useSelector(state => state?.walletData?.wallet?.currency)==="naira";
    const [initialLoading, setInitialLoading] = useState(true)
    const b2b_data = useSelector(state => state?.loginData?.data);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        // setInitialLoading(true)
        IdentityService.getComplianceDocuments(b2b_data?.businessId||b2b_data?.user?.businessId)
            .then((response) => response.data)
            .then((res) => {
                if(res.status === true){
                    if(res?.data?.complianceDocStatus) {
                        nextButton()
                    }
                    setInitialLoading(false)
                }
            })
            .catch((err) => {setInitialLoading(false)});
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const form = useFormik({
        initialValues: {
            // CACRegisteredBusinessName: compliance_data?.CACRegisteredBusinessName || "",
            regType: "", // reg type is not required for international businesses
            regNo: "",
            idType: "",
            idFile: null,
            regFile: null,
            profilePhoto: null,
        },
        validationSchema: complianceDocument(isNigerianBusiness), 
        onSubmit: (values) => {
            setIsForm(false)
        }
    })

    const idTypes = [
        {label: "International Passport", value: "passport"},
        {label: "Drivers License", value: "drivers license"},
        {label: "Voter’s Card", value: "voters card"},
        {label: "National Identity Card", value: "national id"},
    ]


    const submitDocuments = () => {
        const {values} = form
        let data = new FormData();
        data.append('registrationNumber', `${values.regType}${values.regNo}`);
        // data.append('documentType', "CAC");
        // data.append("CACRegisteredBusinessName", values.CACRegisteredBusinessName)
        data.append('idType', values.idType);
        data.append('idFile', values.idFile);
        data.append('regFile', values.regFile);
        if(!!values.profilePhoto){
            data.append('file', values.profilePhoto);
        }
        setIsLoading(true)
        IdentityService.sendComplianceDocuments(data)
            .then((response) => response.data)
            .then((res) => {
                setIsLoading(false)
                if(res.status === true){
                    nextButton()
                }
            })
            .catch((err) => {
                setIsLoading(false)
            });
    }
 
    return(
        <div>
            {
                initialLoading ?
                <FormLoader title="Loading form content" message="Please wait while we load your form content" />
                :
                <div className="box-border">
                    <div className="-mb-[30px]">
                        <Back to={()=> isForm ? prevButton():setIsForm(true)} />
                    </div>
                    <h5 className="text-xl font-medium mb-4">Document</h5>
                    {isForm ?
                        <FormikProvider value={form}>
                            <form onSubmit={form.handleSubmit}>
                                {/* <TextField
                                    label="Enter Registered Business Name"
                                    name='CACRegisteredBusinessName'
                                    type='text'
                                    placeholder="Registered Business Name"
                                    required
                                /> */}
                                {
                                    isNigerianBusiness &&
                                    <SelectField
                                        name='regType'
                                        options={[
                                            { value: 'BN', label: 'Business Name' },
                                            { value: 'RC', label: 'Registered Company' },
                                        ]}
                                        required
                                        label="Select Registration"
                                        onChange={(selected)=>{
                                            form.setFieldValue( "regType", selected.value )
                                        }}
                                        value={form?.values?.regType}
                                    />
                                }
                                <TextField
                                    label="Enter Registration Number"
                                    name='regNo'
                                    type='text'
                                    required
                                    placeholder="Registration number"
                                />
                                <SelectField
                                    name='idType'
                                    options={idTypes}
                                    required
                                    label="Select ID to upload"
                                    onChange={(selected)=>{
                                        form.setFieldValue( "idType", selected.value )
                                    }}
                                    value={form?.values?.idType}
                                />
                                {
                                    !!form.values.idType &&
                                    <div className="-mt-8">
                                        <UploadFile 
                                            name="idFile"
                                            onChange={(file)=>form.setFieldValue("idFile", file)}
                                            maxFileSize={2}
                                        />
                                    </div>
                                }
                                <UploadFile 
                                    label={isNigerianBusiness ? "CAC Document":"Registration Certificate"}
                                    required
                                    name="regFile"
                                    onChange={(file)=>form.setFieldValue("regFile", file)}
                                    maxFileSize={2}
                                />
                                <UploadFile 
                                    label={"Business Logo"}
                                    required
                                    name="profilePhoto"
                                    onChange={(file)=>form.setFieldValue("profilePhoto", file)}
                                    customUploadView={
                                        <div>
                                            <div className="flex justify-between items-center box-border py-1.5 px-[20px] border-[1px] border-[#E3E4E7] rounded-[8px]" style={{cursor:"pointer"}}>
                                                <img src={upload} alt="upload" className="w-8" />
                                                <p>Business Logo</p>
                                            </div>
                                            <p className="text-xs text-red-600">2MB maximum file size</p>
                                        </div>
                                    }
                                    fileType="image"
                                    maxFileSize={2}
                                />
                                {/* <p style={{margin:"15px 0", color:"blue"}}>NB: Please make sure the registered business name entered is exactly the same as it is when registered with CAC</p> */}
                                <Button
                                    isValid={true}
                                    isLoading={false}
                                    type="submit"
                                    text="Save"
                                    className="mt-3 bg-cc-primary"
                                />
                            </form>
                        </FormikProvider>
                        :
                        <div>
                            {/* <div>
                                <h6 className="text-[#5B5B5B] text-[1em]">Registered Business Name</h6>
                                <p className="text-[#353535]">{form.values.CACRegisteredBusinessName}</p>
                            </div> */}
                            <div className="mt-3 mb-6">
                                <h6 className="text-[#5B5B5B] text-[1em]">Registration Number</h6>
                                <p className="text-[#353535]">{form.values.regType}{form.values.regNo}</p>
                            </div>
                            <div className="mt-3 mb-6">
                                <h6 className="text-[#5B5B5B] text-[1em]">ID Document</h6>
                                <p className="text-[#353535]">{idTypes?.find(x => x?.value === form.values?.idType)?.label}</p>
                            </div>
                            <div className="mt-3 mb-6">
                                <h6 className="text-[#5B5B5B] text-[1em]">ID Document File</h6>
                                <p className="text-[#353535]">{form.values?.idFile?.name}</p>
                            </div>
                            <div className="mt-3 mb-6">
                                <h6 className="text-[#5B5B5B] text-[1em]">CAC Document File</h6>
                                <p className="text-[#353535]">{form.values?.regFile?.name}</p>
                            </div>
                            <div className="grid grid-cols-2 gap-6">
                                <Button
                                    isValid={true}
                                    isLoading={false}
                                    type="button"
                                    text="Edit"
                                    className="bg-white !text-[#0C0C0C] !border-[1px] !border-[#D0D5DD]"
                                    onClick={()=>setIsForm(true)}
                                />
                                <Button
                                    isValid={true}
                                    isLoading={isLoading}
                                    type="button"
                                    text="Next"
                                    className="bg-cc-primary"
                                    onClick={()=>submitDocuments()}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}


export default Document;