import { months } from "constants/months";
import { logToBin } from "lib/logs";
import { toast } from "react-toastify";
import { store } from "redux/store";
import jsPDF from "jspdf";
import * as htmlToImage from 'html-to-image';


export const getPublicKey = () => {
    const state = store.getState();
    const publicKey = state?.app?.active?.livePublicKey;
    return publicKey;
}

export const getDateFromDayinYear = (year, day) => {
    var date = new Date(year, 0);
    return new Date(date.setDate(day)).toLocaleDateString();
}

export const currencyFormatter = (
    amount,
    maxDigits=2,
    staticCurrency="", // NGN
) => {
    const state = store.getState();
    const currency = !!staticCurrency ?
        staticCurrency
        : 
        (state?.walletData?.wallet?.currency === "usd" ? "USD":"NGN");
    
    var formatMoney = new Intl.NumberFormat(
        currency==="USD" ? 'en-US':'en-NG', 
        {
            style: 'currency',
            currency: currency,
            maximumFractionDigits: maxDigits, 
        }
    );
    return formatMoney.format(isNaN(amount)||amount===Infinity ? 0:amount)
}

export const dateFormatter = (
    date = new Date(), 
    format
) => {
    let result = ''; 
    const d = new Date(date).getDate();
    const m = new Date(date).getMonth();
    const y = new Date(date).getFullYear();
    if(format==='MMM dd'){ // Jan 10
        result = `${months[m].slice(0,3)} ${d}`
    } else if (format==='dd-M-yyyy') { // 10-January-2023
        result = `${d}-${months[m]}-${y}`
    } else if (format==='dd/mm/yyyy') { // 10/1/2023
        result = `${d<10 ? '0':""}${d}/${m<9 ? '0':""}${m+1}/${y}`
    } else if (format==='dd-mm-yyyy') { // 10-01-2023
        result = `${d<10 ? '0':""}${d}-${m<9 ? '0':""}${m+1}-${y}`
    } else if (format==='dd MMM yyyy') { // 10 Jan 2023
        result = `${d} ${months[m]?.slice(0,3)} ${y}`
    } else if (format==='MMM dd, yyyy') { // Jan 10, 2023
        result = `${months[m]?.slice(0,3)} ${d}, ${y}`
    } else if (format==='yyyy-mm-dd') { // 2023-01-10
        result = `${y}-${m<9 ? '0':""}${m+1}-${d<10 ? '0':""}${d}`
    } else {
        result = date?.slice(0,10)
    }
    return result;
}

export const timeFormatter = (
    time
) => {
    let date = new Date(time);
    var hours = date?.getHours();
    var minutes = date?.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ampm;
    return strTime;
}

export const getInitials = (item) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    let initials = [...item?.matchAll(rgx)] || [];
    initials = (
        (initials?.shift()?.[1] || '') + (initials?.pop()?.[1] || '')
    )?.toUpperCase();
    return initials;
}

export const formatArrayPaging = (data, noPerPage) => {
    // Get number of elements per subarray
    const arrayPerGroup = noPerPage;
    // Create array based on number of groups
    const result = new Array(Math.ceil(data.length / noPerPage))
    // Make sure each element isn't empty
    .fill('')
    // For each group, grab the right `slice` of the input array
    .map((_, i) => data.slice(i * arrayPerGroup, (i + 1) * arrayPerGroup));
    return result;
}

export const handleRequestErrors = (err, noMessageToast=false, timeout=15000) => {
    if(err.response){
        logToBin(err.response.data, window.location.href);
        !noMessageToast && toast.error(err.response.data.message);
    } else{
        if(err.message === `timeout of ${timeout}ms exceeded`){
            toast.error('Request timed out, please try again!')
        } else if (err.message==="Network Error"){
            toast.error('Network error, please make sure you are connected to the internet!')
        } else{
            toast.error("Your request can't be processed at this time, please try again later!");
        }
    }
}

export const getTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours}:${minutes}`;
}

export const getDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() +1);
    const day = (date.getDate());
    return `${day}/${month}/${year}`;
}

export const getBankLogo = (ngBanks, bankCode, dimension) => {
    return (
        <div className="bg-slate-200" style={{width: dimension ?? "100px", height: dimension ?? "100px"}}>
            <img style={{width:"100%", height:"100%"}} src={ngBanks.find(o => o.code === bankCode)?.logo?.url } alt="icon" />
        </div>
    )
}

export const copyText = (
    textToCopy,
    message="Text copied!",
) => {
    navigator.clipboard.writeText(textToCopy);
    toast.success(message)
}

export const capitalizeFirstLetter = (item) => {
    let capitalizedString = item.charAt(0).toUpperCase() + item.slice(1);
    return capitalizedString;
}

export const generatePDF = (
    id, 
    fileName, 
    handleSuccess,
    toBeDownloaded=true,
) => {
    const content = document.getElementById(id);
    const pdf = new jsPDF('p', 'mm', 'a4', true); // true value indicates that pdf should be compressed
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    let yOffset = 0;

    const addPage = () => {
        htmlToImage
            .toPng(content, {
                quality: 0.95,
                style: {
                    transform: `translateY(-${yOffset}px)`,
                },
            })
            .then((dataUrl) => {
                const imgProps = pdf.getImageProperties(dataUrl);
                const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

                if (yOffset !== 0) pdf.addPage();
                pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, imgHeight);

                yOffset += pdfHeight * 3.7795275591;

                if (yOffset < content.scrollHeight) {
                    addPage();
                } else {
                    if(toBeDownloaded){
                        pdf.save(`${fileName}.pdf`);
                        handleSuccess()
                    } else {
                        let blob = pdf.output('blob') // blob/datauristring/pdf
                        handleSuccess(blob)
                    }
                }
            });
    };

    addPage();
}

export function calculateDuration (start, end) {
    // Convert dates to milliseconds
    const startDate = new Date(start).getTime();
    const endDate = new Date(end).getTime();
    
    // Calculate the difference in milliseconds
    const diffTime = Math.abs(endDate - startDate);

    // Calculate days, weeks, and months
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    const diffWeeks = diffDays / 7;
    const diffMonths = diffDays / 30.44; // average days in a month

    // Determine the most appropriate unit
    if (diffMonths >= 1) {
        return `${Math.floor(diffMonths)} month(s)`;
    } else if (diffWeeks >= 1) {
        return `${Math.floor(diffWeeks)} week(s)`;
    } else {
        return `${Math.floor(diffDays)} day(s)`;
    }
}

export function formatFileSize(bytes=0) {
    if (bytes < 1024) {
      return bytes + ' B';
    } else if (bytes < 1048576) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else {
      return (bytes / 1048576).toFixed(2) + ' MB';
    }
  }